/* eslint-disable */
/* eslint-disable fp/no-let */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-conditional */
/* eslint-disable no-prototype-builtins */
/* eslint-disable max-lines */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import CmsBlock from 'Component/CmsBlock';
import IfStore from 'Component/IfStore';
import Link from 'Component/Link';
import {
    ARMADI_ANTA_BATTENTE_CATEGORIES,
    ARMADI_ANTA_BATTENTE_REGEXP,
    ARMADI_ANTA_SCORREVOLE_CATEGORIES,
    ARMADI_ANTA_SCORREVOLE_REGEXP,
    BED_CATEGORIES,
    CAMERE_COMPLETE_MODERNE_CATEGORIES,
    CAMERE_COMPLETE_MODERNE_REGEXP,
    CAMERETTE_CATEGORIES,
    CAMERETTE_REGEXP,
    COMO_CASSETTIERE_COMODINI_CATEGORIES,
    COMO_CASSETTIERE_COMODINI_REGEXP,
    COMPOSIZIONI_FISSE_REGEXP,
    CUCINE_ELETTRODOMESTICI_REGEXP,
    DIVANI_2_3_POSTI_CATEGORIES,
    DIVANI_2_3_POSTI_REGEXP,
    DIVANI_LETTO_CATEGORIES,
    DIVANI_LETTO_REGEXP,
    DIVANI_POLTRONE_RELAX_CATEGORIES,
    DIVANI_POLTRONE_RELAX_REGEXP,
    ILLUMINAZIONE_CATEGORIES,
    ILLUMINAZIONE_REGEXP,
    KITCHEN_CATEGORIES,
    KITCHEN_FORNITURES_CATEGORIES,
    LETTI_REGEXP,
    MOBILI_BAGNI_REGEXP,
    MOBILI_BAGNO_CATEGORIES,
    N_OF_POSITIONS_BEFORE_BED,
    N_OF_POSITIONS_BEFORE_CM
} from 'Component/McMenuFullScreen/McMenuFullScreen.config';
import { McMenuType } from 'Type/McMenuType';
import { isSignedIn } from '@scandipwa/scandipwa/src/util/Auth/IsSignedIn';

import { MAX_DEEPTH_MENU } from './McList.config';

import './McList.style';

/** @namespace Pwa/Component/McList/Component/McListComponent */
export class McListComponent extends PureComponent {
    static propTypes = {
        menu: McMenuType.isRequired,
        handleSubcategoryClick: PropTypes.func.isRequired,
        depth: PropTypes.number.isRequired,
        handleLastLevelSubCategoryClick: PropTypes.func.isRequired,
        isSubCategoryClicked: PropTypes.bool,
        subCategoryClicked: PropTypes.string,
        doLogout: PropTypes.func.isRequired,
        flagConfigurator: PropTypes.string.isRequired,
        rewriteUrls: PropTypes.func.isRequired
    };

    static defaultProps = {
        isSubCategoryClicked: false,
        subCategoryClicked: ''
    };

    state = {
        isCollectionSubCategoryClicked: false
    };

    renderListItem(item, customName = '', disableIsOfferte = false) {
        const { nodes, enableSubLevels } = item;
        const { depth } = this.props;
        if (nodes && nodes.length > 0 && (depth < MAX_DEEPTH_MENU || enableSubLevels)) {
            return this.renderCategoryItem(item);
        }

        return this.renderLinkItem(item, customName, disableIsOfferte);
    }

    retrieveTags(item, type = '') {
        const availableTags = [];
        // eslint-disable-next-line fp/no-let
        let regex;
        if (type === 'kitchen') {
            regex = new RegExp(COMPOSIZIONI_FISSE_REGEXP);
        } else if (type === 'bed') {
            regex = new RegExp(LETTI_REGEXP);
        } else if (type === 'cameretta') {
            regex = new RegExp(CAMERETTE_REGEXP);
        } else if (type === 'cucine-elettrodomestici') {
            regex = new RegExp(CUCINE_ELETTRODOMESTICI_REGEXP);
        } else if (type === 'divani-letto') {
            regex = new RegExp(DIVANI_LETTO_REGEXP);
        } else if (type === 'divani-2-3-posti') {
            regex = new RegExp(DIVANI_2_3_POSTI_REGEXP);
        } else if (type === 'camere-complete-moderne') {
            regex = new RegExp(CAMERE_COMPLETE_MODERNE_REGEXP);
        } else if (type === 'camere-complete-moderne') {
            regex = new RegExp(CAMERE_COMPLETE_MODERNE_REGEXP);
        } else if (type === 'armadi-anta-battente') {
            regex = new RegExp(ARMADI_ANTA_BATTENTE_REGEXP);
        } else if (type === 'armadi-anta-scorrevole') {
            regex = new RegExp(ARMADI_ANTA_SCORREVOLE_REGEXP);
        } else if (type === 'como-cassettiere-comodini') {
            regex = new RegExp(COMO_CASSETTIERE_COMODINI_REGEXP);
        } else if (type === 'illuminazione') {
            regex = new RegExp(ILLUMINAZIONE_REGEXP);
        } else if (type === 'divani-poltrone-relax') {
            regex = new RegExp(DIVANI_POLTRONE_RELAX_REGEXP);
        } else if (type === 'mobili-bagno') {
            regex = new RegExp(MOBILI_BAGNI_REGEXP);
        }

        if (regex !== undefined && item.hasOwnProperty('nodes') && Array.isArray(item.nodes) && item.nodes.length > 0) {
            item.nodes.forEach((ele) => {
                if (ele.hasOwnProperty('name') && (typeof ele.name === 'string')
                    && ele.name.length > 0 && regex.test(ele.name)) {
                    availableTags.push(ele);
                }
            });
        }

        return availableTags;
    }

    deleteBackArrow() {
        const arrow = document.getElementById('mclist-back-arrow');
        window.scrollTo(0, 0);
        if (arrow !== undefined && arrow !== null) {
            arrow.remove();
        }
    }

    renderCameretteTag(tag) {
        const measure = tag.name.trim();

        return (
            <div
              block="McList"
              elem="SubMenu-Element"
              className="McList-SubMenu-Element-Camerette"
            >
                <Link
                  to={ tag.url }
                  block="McList"
                  elem="SubMenu-Link"
                  onClick={ () => {
                      this.deleteBackArrow();
                  } }
                >
                       <span
                         block="McList"
                         elem="SubMenu-Text"
                       >
                            { measure }
                       </span>
                </Link>
            </div>
        );
    }

    renderBedTag(tag) {
        // const { subCategoryClicked } = this.props;
        // const subcategoryName = tag.name.slice(0, tag.name.indexOf('-')).trim().toLowerCase();
        const measure = tag.name.slice((tag.name.indexOf('-') + N_OF_POSITIONS_BEFORE_BED)).trim();

        return (
            <div
              block="McList"
              elem="SubMenu-Element"
              className="McList-SubMenu-Element-Bed"
            >
                <Link
                  to={ tag.url }
                  block="McList"
                  elem="SubMenu-Link"
                  onClick={ () => {
                      this.deleteBackArrow();
                  } }
                >
                       <span
                         block="McList"
                         elem="SubMenu-Text"
                       >
                            { measure }
                       </span>
                </Link>
            </div>
        );
    }

    renderKitchenTag(tag) {
        const measure = tag.name.slice((tag.name.indexOf('cm') - N_OF_POSITIONS_BEFORE_CM)).trim();

        return (
            <div
              block="McList"
              elem="SubMenu-Element"
            >
                <Link
                  to={ tag.url }
                  block="McList"
                  elem="SubMenu-Link"
                  onClick={ () => {
                      this.deleteBackArrow();
                  } }
                >
                       <span
                         block="McList"
                         elem="SubMenu-Text"
                       >
                            { measure }
                       </span>
                </Link>
            </div>
        );
    }

    renderGenericTag(tag, baseUrl = null) {
        const measure = tag.name.trim();

        return (
            <div
              block="McList"
              elem="SubMenu-Element"
            >
                <Link
                  to={ baseUrl ? `${baseUrl}${tag.url}` : tag.url }
                  block="McList"
                  elem="SubMenu-Link"
                  onClick={ () => {
                      this.deleteBackArrow();
                  } }
                >
                       <span
                         block="McList"
                         elem="SubMenu-Text"
                       >
                            { measure }
                       </span>
                </Link>
            </div>
        );
    }

    renderGenericCollectionTag(tag, baseUrl = null) {
        const measure = tag.name.trim();
        return (
            <div
              block="McList"
              elem="SubMenu-Element"
            >
                <a
                  href={ baseUrl ? `${baseUrl.replaceAll(' ', '-')}${tag.url}` : tag.url }
                  block="McList"
                  elem="SubMenu-Link"
                  onClick={ () => {
                      this.deleteBackArrow();
                  } }
                >
                       <span
                         block="McList"
                         elem="SubMenu-Text"
                       >
                            { measure }
                       </span>
                </a>
            </div>
        );
    }

    redirectOnCategoryUrl(url) {
        window.location.href = url;
    }

    renderLinkItem(item, customName = '', disableIsOfferte = false) {
        const {
            name, url, is_offerte: isOfferte, path
        } = item;

        const displayName = (customName.length > 0) ? customName : name;
        const customKey = (customName.length > 0) ? `${customName}_${path}` : path;
        const customMods = (disableIsOfferte) ? {} : { isOfferte };

        const {
            isSubCategoryClicked, handleLastLevelSubCategoryClick, subCategoryClicked, rewriteUrls
        } = this.props;

        const custom_url = rewriteUrls(url);

        const aux = path.split('/');
        const categoryId = aux[aux.length - 1];

        const isKitchen = KITCHEN_CATEGORIES.includes(categoryId);
        const isBed = BED_CATEGORIES.includes(categoryId);
        const isCameretta = CAMERETTE_CATEGORIES.includes(categoryId);
        const isKitchenfornitures = KITCHEN_FORNITURES_CATEGORIES.includes(categoryId);
        const isDivaniLetto = DIVANI_LETTO_CATEGORIES.includes(categoryId);
        const isDivani_2_3_Posti = DIVANI_2_3_POSTI_CATEGORIES.includes(categoryId);
        const isCamereCompleteModerne = CAMERE_COMPLETE_MODERNE_CATEGORIES.includes(categoryId);
        const isArmadiAnteBattente = ARMADI_ANTA_BATTENTE_CATEGORIES.includes(categoryId);
        const isArmadiAntaScorrevole = ARMADI_ANTA_SCORREVOLE_CATEGORIES.includes(categoryId);
        const isComoCassettiereComodini = COMO_CASSETTIERE_COMODINI_CATEGORIES.includes(categoryId);
        const isIlluminazione = ILLUMINAZIONE_CATEGORIES.includes(categoryId);
        const isDivaniPoltroneRelax = DIVANI_POLTRONE_RELAX_CATEGORIES.includes(categoryId);
        const isMobiliBagno = MOBILI_BAGNO_CATEGORIES.includes(categoryId);

        const isSubMenu = isKitchen || isBed || isCameretta || isKitchenfornitures || isDivaniLetto || isDivani_2_3_Posti || isCamereCompleteModerne || isArmadiAnteBattente
        || isArmadiAntaScorrevole || isComoCassettiereComodini || isIlluminazione || isDivaniPoltroneRelax || isMobiliBagno;

        let type = '';
        if (isKitchen) {
            type = 'kitchen';
        } else if (isBed) {
            type = 'bed';
        } else if (isCameretta) {
            type = 'cameretta';
        } else if (isKitchenfornitures) {
            type = 'cucine-elettrodomestici';
        } else if (isDivaniLetto) {
            type = 'divani-letto';
        } else if (isDivani_2_3_Posti) {
            type = 'divani-2-3-posti';
        } else if (isCamereCompleteModerne) {
            type = 'camere-complete-moderne';
        } else if (isArmadiAnteBattente) {
            type = 'armadi-anta-battente';
        } else if (isArmadiAntaScorrevole) {
            type = 'armadi-anta-scorrevole';
        } else if (isComoCassettiereComodini) {
            type = 'como-cassettiere-comodini';
        } else if (isIlluminazione) {
            type = 'illuminazione';
        } else if (isDivaniPoltroneRelax) {
            type = 'divani-poltrone-relax';
        } else if (isMobiliBagno) {
            type = 'mobili-bagno';
        }
        if (isSubMenu && ((subCategoryClicked === '') || (subCategoryClicked !== '' && name.toLowerCase() === subCategoryClicked))) {
            const tags = this.retrieveTags(item, type);

            // const backArrow = document.getElementsByClassName('Header-Button Header-Button_type_back Header-Button_isVisible');
            const backArrow = document.querySelectorAll('.Header-Button.Header-Button_type_back.Header-Button_isVisible');
            const xButton = document.querySelector('.Header-Button_type_close');
            let new_element = document.getElementById('mclist-back-arrow');
            if (isSubCategoryClicked
                && tags.length > 0) {
                if (backArrow !== null && backArrow !== undefined && backArrow.length > 0) {
                    if (new_element === null) {
                        new_element = backArrow[0].cloneNode(true);
                        new_element.id = 'mclist-back-arrow';
                        /** hide element and event listeners * */
                        backArrow[0].style.display = 'none';
                        /** insert new identical element * */
                        backArrow[0].parentNode.appendChild(new_element);
                        new_element.addEventListener('click', () => {
                            handleLastLevelSubCategoryClick();
                        });
                    } else {
                        backArrow[0].style.display = 'none';
                        new_element.style.display = 'block';
                        new_element.addEventListener('click', () => {
                            handleLastLevelSubCategoryClick();
                        });
                    }
                    xButton.addEventListener('click', () => {
                        new_element.style.display = 'none';
                        handleLastLevelSubCategoryClick();
                    });
                }
            } else {
                if (backArrow !== null && backArrow !== undefined && backArrow.length > 0) {
                    backArrow[0].style.display = '';
                }
                if (new_element !== null && new_element !== undefined) {
                    new_element.style.display = 'none';
                }
            }

            // eslint-disable-next-line no-return-assign
            return (
                <>
                    <li
                      block="McList"
                      elem="Item"
                      id={ displayName }
                      key={ customKey }
                      onClick={ tags.length > 0 ? handleLastLevelSubCategoryClick : () => {
                          this.redirectOnCategoryUrl(url);
                      } }
                      role="button"
                    >
                    <span
                      block="McList"
                      elem="Text"
                      mods={ customMods }
                    >
                        { displayName }
                    </span>
                    </li>
                    { isSubCategoryClicked
                        && tags.length > 0 && (
                            <div
                              block="McList"
                              elem="SubMenu"
                            >
                                { (isKitchen || isCameretta || isKitchenfornitures || isDivaniLetto || isDivani_2_3_Posti || isMobiliBagno
                                || isCamereCompleteModerne || isArmadiAnteBattente || isArmadiAntaScorrevole || isComoCassettiereComodini || isIlluminazione || isDivaniPoltroneRelax) && (
                                    <div
                                      block="McList"
                                      elem="SubMenu-Element"
                                    >
                                        <Link
                                          to={ custom_url }
                                          block="McList"
                                          elem="SubMenu-Link"
                                          onClick={ () => {
                                              this.deleteBackArrow();
                                          } }
                                        >
                                            <span
                                              block="McList"
                                              elem="SubMenu-Text"
                                            >
                                                Tutto
                                            </span>
                                        </Link>
                                    </div>
                                ) }
                                { isBed && (
                                    <div
                                      block="McList"
                                      elem="SubMenu-Element"
                                    >
                                        <Link
                                          to={ custom_url }
                                          block="McList"
                                          elem="SubMenu-Link"
                                          onClick={ () => {
                                              this.deleteBackArrow();
                                          } }
                                        >
                                <span
                                  block="McList"
                                  elem="SubMenu-Text"
                                >
                                    { __('All') }
                                </span>
                                        </Link>
                                    </div>
                                ) }
                                { isKitchen && tags.map((tag) => this.renderKitchenTag(tag)) }
                                { isBed && tags.map((tag) => this.renderBedTag(tag)) }
                                { isCameretta && tags.map((tag) => this.renderCameretteTag(tag)) }
                                { (isKitchenfornitures || isDivani_2_3_Posti || isDivaniLetto || isCamereCompleteModerne || isArmadiAnteBattente || isArmadiAntaScorrevole
                                || isComoCassettiereComodini
                                || isIlluminazione
                                || isDivaniPoltroneRelax || isMobiliBagno)
                                && tags.map((tag) => this.renderGenericTag(tag)) }
                            </div>
                    ) }
                </>
            );
        }

        /* avoid displaying not clicked categories for Kitchens only */
        if (!isSubCategoryClicked) {
            return (
                <li block="McList" elem="Item" key={ customKey }>
                    <Link to={ custom_url } block="McList" elem="Link">
                    <span
                      block="McList"
                      elem="Text"
                      mods={ customMods }
                    >
                        { displayName }
                    </span>
                    </Link>
                </li>
            );
        }

        return null;
    }

    renderKitchenTagConfigurator() {
        return (
            <li block="McList" elem="Item">
                <a
                  href={ __('/configuratore-cucine') }
                  className="configuratoreCucine"
                  onClick={ () => {
                      window?.dataLayer?.push({
                          event: 'kitchen_configurator',
                          category: 'kitchen_configurator',
                          action: 'start',
                          start_point: 'menu',
                          category_name: 'cucine'
                      });
                  } }
                >
                    { __('CONFIGURA E ACQUISTA') }
                    { ' ' }
                    &nbsp;
                    <i className="fa fa-caret-right" aria-hidden="true" />
                </a>
            </li>
        );
    }

    renderCollectionChairButton() {
        return (
            <li block="McList" elem="Item">
                <a
                  href={ __('/collezione-sedie-interno-esterno-design/') }
                  className="collection chair"
                >
                    { __('Sedie da interno ed esterno') }
                </a>
            </li>
        );
    }

    renderButtonCollection(collection, data) {
        const url = `/collezioni/${data}`;

        const { isCollectionSubCategoryClicked } = this.state;

        this.changeGoBackLogic();
        const shouldRenderCollection = (collection.length > 0) && collection.some((tag) => tag.includeInMenu);

        return (
            <>
            { !(isCollectionSubCategoryClicked) && (shouldRenderCollection) && (
            <li block="McList" elem="Item">
            <div
              className="collection"
              role="button"
              onClick={ () => {
                  this.setState((prevState) => ({ isCollectionSubCategoryClicked: !prevState.isCollectionSubCategoryClicked }));
              } }
            >
                    { __('Collezioni') }
            </div>
            </li>
            ) }
            { isCollectionSubCategoryClicked && collection.length > 0 && (
                    <div
                      block="McList"
                      elem="SubMenu"
                    >
                        <div
                          block="McList"
                          elem="SubMenu-Element"
                        >
                            <div
                              block="McList"
                              elem="SubMenu-Element-Dash"
                            />
                            <a
                              href={ url }
                              block="McList"
                              elem="SubMenu-Link"
                              onClick={ () => window.scrollTo(0, 0) }
                            >
                                <span
                                  block="McList"
                                  elem="SubMenu-Text"
                                >
                                    Tutte
                                </span>
                            </a>
                        </div>
                        { collection.filter((tag) => tag.includeInMenu).map((tag) => this.renderGenericCollectionTag(tag, url)) }
                    </div>
            ) }
            </>
        );
    }

    async changeGoBackLogic() {
        const { isCollectionSubCategoryClicked } = this.state;

        // const backArrow = document.getElementsByClassName('Header-Button Header-Button_type_back Header-Button_isVisible');
        const backArrow = document.querySelectorAll('.Header-Button.Header-Button_type_back.Header-Button_isVisible');
        let new_element = document.getElementById('mclist-collection-back-arrow');
        const xButton = document.querySelector('.Header-Button_type_close');

        if (new_element !== null && !isCollectionSubCategoryClicked) {
            new_element.style.display = 'none';
        }

        if (backArrow !== null && backArrow !== undefined && backArrow.length > 0 && isCollectionSubCategoryClicked) {
            if (new_element === null) {
                new_element = backArrow[0].cloneNode(true);
                new_element.id = 'mclist-collection-back-arrow';
                backArrow[0].style.display = 'none';
                backArrow[0].parentNode.appendChild(new_element);
                new_element.addEventListener('click', () => {
                    this.setState({ isCollectionSubCategoryClicked: false });
                    backArrow[0].style.display = 'block';
                    new_element.style.display = 'none';
                });
            } else {
                backArrow[0].style.display = 'none';
                new_element.style.display = 'block';
                new_element.addEventListener('click', () => {
                    this.setState({ isCollectionSubCategoryClicked: false });
                    backArrow[0].style.display = 'block';
                    new_element.style.display = 'none';
                });
            }
            xButton.addEventListener('click', () => {
                this.setState({ isCollectionSubCategoryClicked: false });
                backArrow[0].style.display = 'block';
                new_element.style.display = 'none';
            });
        }
    }

    renderCategoryItem(item, disableHandler = false) {
        const {
            name, path, is_offerte: isOfferte
        } = item;

        const { handleSubcategoryClick } = this.props;

        const clickHandler = (disableHandler) ? () => {
        } : (e) => handleSubcategoryClick(e, item);

        return (
            <li block="McList" elem="Item" key={ path }>
                <div
                  block="McList"
                  elem="Link"
                  role="button"
                  tabIndex="0"
                  onClick={ clickHandler }
                >
                    <span
                      block="McList"
                      elem="Text"
                      mods={ { isOfferte: (isOfferte && !disableHandler), isMainMenu: !disableHandler } }
                    >
                        { name }
                    </span>
                </div>
            </li>
        );
    }

    renderSocialIcon(item) {
        const { icon, url } = item;

        return (
            <li block="McList" elem="Element">
                <Link to={ url } block="McList" elem="Icon">
                    <i className={ `fa fa-${icon}` } />
                </Link>
            </li>
        );
    }

    renderMenuList() {

        console.log("renderMenuList this.props", this.props)

        const {
            // eslint-disable-next-line react/prop-types
            menu: { nodes: children = [], ...father }, depth, flagConfigurator, collections, flagConfiguratorChair
        } = this.props;
        const { isCollectionSubCategoryClicked } = this.state;
        const isSecondLevel = depth > 0;
        // CONFIGURATORE CUCINE
        const isKitchen = father.name?.toLowerCase() === 'cucine' && (flagConfigurator) === '1';
        const isChair = father.name?.toLowerCase() === 'tavoli e sedie' && flagConfiguratorChair === '1';

        const isCollections = father.name?.toLowerCase() in collections;

        return children.length > 0 && (
            <ul block="McList" elem="Menu" mods={ { isMainMenu: true, isSecondLevel } } key={ depth }>
                { isSecondLevel && !(isCollectionSubCategoryClicked && isCollections) && (
                    <>
                        { (!father.is_offerte) && (
                            <>
                                { this.renderListItem(father, '', isSecondLevel) }
                                { this.renderListItem(father, __('All'), isSecondLevel) }
                            </>
                        ) }
                        { (father.is_offerte) && this.renderCategoryItem(father, true) }

                    </>
                ) }
                { !(isCollectionSubCategoryClicked && isCollections) && children.map((item) => {
                    const sub = {
                        ...item,
                        enableSubLevels: father.enableSubLevels
                    };

                    return this.renderListItem(sub, '', isSecondLevel);
                }) }
                { isKitchen && !(isCollectionSubCategoryClicked && isCollections) && this.renderKitchenTagConfigurator() }
                { isChair && !isCollectionSubCategoryClicked && this.renderCollectionChairButton() }
                <IfStore storeCode="default">
                    { isCollections && this.renderButtonCollection(collections[father.name.toLowerCase()], father.name.toLowerCase()) }
                </IfStore>
            </ul>
        );
    }

    render() {
        const { doLogout } = this.props;

        return (
            <div block="McList" elem="Wrapper" key="MAIN_MENU_MOBILE">
                <div>
                    { this.renderMenuList() }
                </div>
                <div>
                    <CmsBlock identifier="menu-links-mobile" />
                    { isSignedIn() && (
                        <IfStore storeCode="default">
                            <button
                              onClick={ () => {
                                  doLogout();
                                  if (window.location.pathname === '/menu') {
                                      window.location.replace('/');
                                  }
                              } }
                              className="logout"
                            >
                                { __('Esci dall\'account') }
                            </button>
                        </IfStore>
                    ) }
                    <CmsBlock identifier="menu-social-mobile" />
                </div>
            </div>
        );
    }
}

export default McListComponent;
