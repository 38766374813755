import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Page } from '@scandipwa/scandipwa/src/component/Header/Header.config'
// import { MENU } from 'Component/Header/Header.config';
import McList from 'Component/McList';
import {
    mapDispatchToProps,
    mapStateToProps,
    MenuPageContainer as SourceMenuPageContainer
} from 'SourceRoute/MenuPage/MenuPage.container';
import history from 'Util/History';

import './MenuPage.override.style';

export {
    mapStateToProps,
    mapDispatchToProps
};

/** @namespace Pwa/Route/MenuPage/Container/MenuPageContainer */
export class MenuPageContainer extends SourceMenuPageContainer {
    componentDidMount() {
        const { updateMeta, changeHeaderState } = this.props;
        updateMeta({ title: __('Menu') });
        this.redirectIfNotOnMobile();
        changeHeaderState({
            name: Page.MENU,
            onCloseClick: () => history.goBack()
        });
    }

    render() {
        return (
            <main block="MenuPage">
                <McList />
            </main>
        );
    }
}

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(MenuPageContainer)
);
