/* eslint-disable react/prop-types */
/* eslint-disable max-lines */
/* eslint-disable no-prototype-builtins,max-len */
import Cookies from 'js-cookie';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Page } from '@scandipwa/scandipwa/src/component/Header/Header.config'

// import { MENU, MENU_SUBCATEGORY } from 'Component/Header/Header.config';
import { changeNavigationState, goToPreviousNavigationState } from 'Store/Navigation/Navigation.action';
// import { TOP_NAVIGATION_TYPE } from 'Store/Navigation/Navigation.reducer';
import { NavigationType } from '@scandipwa/scandipwa/src/store/Navigation/Navigation.type'

import { McMenuType } from 'Type/McMenuType';
import history from '@scandipwa/scandipwa/src/util/History';

import McList from './McList.component';

/** @namespace Pwa/Component/McList/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    menuTree: state.McMenuReducer.menuTree,
    flagConfigurator: state.McMenuReducer.flagConfigurator,
    flagConfiguratorChair: state.McMenuReducer.flagConfiguratorChair,
    collections: state.McMenuReducer.collections
});

export const MyAccountDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/MyAccount/MyAccount.dispatcher'
);

/** @namespace Pwa/Component/McList/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    goToPreviousHeaderState: () => dispatch(goToPreviousNavigationState(NavigationType.TOP_NAVIGATION_TYPE)),
    changeHeaderState: (state) => dispatch(changeNavigationState(NavigationType.TOP_NAVIGATION_TYPE, state)),
    logout: () => {
        MyAccountDispatcher.then(
            ({ default: dispatcher }) => dispatcher.logout(false, dispatch)
        );
    }
});

/** @namespace Pwa/Component/McList/Container/McListContainer */
export class McListContainer extends PureComponent {
    static propTypes = {
        menuTree: McMenuType.isRequired,
        goToPreviousHeaderState: PropTypes.func.isRequired,
        changeHeaderState: PropTypes.func.isRequired
    };

    containerFunctions = {
        handleSubcategoryClick: this.handleSubcategoryClick.bind(this),
        sub: this.handleSubcategoryClick.bind(this),
        closeMenu: this.closeMenu.bind(this),
        handleLastLevelSubCategoryClick: this.handleLastLevelSubCategoryClick.bind(this),
        doLogout: this.doLogout.bind(this),
        changeMainMenuHeader: this.changeMainMenuHeader.bind(this),
        handleHeaderCloseClick: this.closeMenu.bind(this),
        rewriteUrls: this.rewriteUrls.bind(this)
    };

    componentDidMount() {
        const { menuTree } = this.props;
        console.log("componentDidMount menuTree", menuTree)
        window.addEventListener('popstate', this.historyBackHook);
        this.scrollTop();
        this.setState({
            menu: menuTree,
            activeMenuItemsStack: []
        });
    }

    componentWillUnmount() {
        window.removeEventListener('popstate', this.historyBackHook);
    }

    scrollTop() {
        window.scrollTo(0, 0);
    }

    doLogout() {
        const { logout } = this.props;
        Cookies.remove('auth_token', { path: '', domain: '.mondoconv.it' });
        Cookies.remove('auth_token_sso', { path: '', domain: '.mondoconv.it' });
        Cookies.remove('user_firstname', { path: '', domain: '.mondoconv.it' });
        Cookies.remove('user_lastname', { path: '', domain: '.mondoconv.it' });
        Cookies.remove('customer', { path: '', domain: '.mondoconv.it' });
        logout();
    }

    findSubList(menu, currentPath, originalPath, enableSubLevels) {
        const current = menu.find((item) => item.path === currentPath);
        if (current) {
            if (currentPath === originalPath) {
                return {
                    ...current,
                    enableSubLevels: current.is_offerte || enableSubLevels
                };
            }
            const { nodes: newMenu, is_offerte } = current;
            return this.findSubList(newMenu, originalPath, originalPath, is_offerte || enableSubLevels);
        }

        const newPath = this.removePathSlice(currentPath);
        return this.findSubList(menu, newPath, originalPath);
    }

    createMenu(path) {
        const { menuTree: { nodes } } = this.props;
        return this.findSubList(nodes, path, path, false);
    }

    rewriteUrls(url) {
        switch (url) {
        case '/offerte/angolo-delle-occasioni.html':
        case '/offerte/offerte/angolo-delle-occasioni.html':
            return '/angolo-delle-occasioni';
        default:
            return url;
        }
    }

    removePathSlice(currentPath) {
        const arr = currentPath.split('/');
        arr.pop();
        return arr.join('/');
    }

    changeMainMenuHeader() {
        const { changeHeaderState } = this.props;

        changeHeaderState({
            name: Page.MENU,
            onCloseClick: () => this.handleHeaderCloseClick()
        });
    }

    changeSubMenuHeader() {
        const { changeHeaderState } = this.props;

        changeHeaderState({
            name: Page.MENU_SUBCATEGORY,
            force: true,
            onBackClick: () => this.handleHeaderBackClick(),
            onCloseClick: () => this.handleHeaderCloseClick()
        });
    }

    handleLastLevelSubCategoryClick(e) {
        const { isSubCategoryClicked, subCategoryClicked } = this.state;

        if (!(e == null && e === undefined) && e.hasOwnProperty('currentTarget') && (e.currentTarget.id !== 'mclist-back-arrow')) {
            const subCategoryClickedNew = String(e.currentTarget.firstChild.innerText).toLowerCase().trim();
            this.setState({ subCategoryClicked: subCategoryClickedNew });
            /* if we simply change subcategory in the same category, leave isSubCategoryClicked at true */
            if (isSubCategoryClicked === true && subCategoryClicked !== '' && subCategoryClickedNew !== subCategoryClicked) {
                return;
            }
            this.setState({
                isSubCategoryClicked: !isSubCategoryClicked
            });
        } else {
            /* click on mclist-back-arrow */
            this.setState({ subCategoryClicked: '' });
            this.setState({
                isSubCategoryClicked: false
            });
            /* PREVIOUSLY BED OPENED IN THE SAME TAB - in case of beds, we should go back to first menu page, after having closed the submenu */
            // if (BED_CATEGORIES.includes(subCategoryClicked) && document.getElementsByClassName('Header-Button Header-Button_type_back Header-Button_isVisible').length === 1) {
            //     document.getElementsByClassName('Header-Button Header-Button_type_back Header-Button_isVisible')[0].click();
            // }
        }
    }

    handleSubcategoryClick(e, activeSubcategory) {
        const { activeMenuItemsStack } = this.state;
        const { path } = activeSubcategory;

        e.stopPropagation();

        if (activeMenuItemsStack.includes(path)) {
            return;
        }

        this.changeSubMenuHeader();
        const menuMenu = this.createMenu(path);
        const newActiveMenuItemsStack = [path, ...activeMenuItemsStack];

        this.setState({
            activeMenuItemsStack: newActiveMenuItemsStack,
            menu: menuMenu
        });

        // keep the stack here, so later we can de-construct menu out of it
        const { pathname } = location;

        window.scrollTo(0, 0);
        history.push(pathname, { stack: newActiveMenuItemsStack });
    }

    handleHeaderBackClick = () => {
        history.goBack();
    };

    handleHeaderCloseClick = () => {
        const { activeMenuItemsStack } = this.state;
        history.go(-(activeMenuItemsStack.length + 1));
        this.closeMenu();
    };

    containerProps = () => {
        const {
            menu, activeMenuItemsStack, isSubCategoryClicked, subCategoryClicked
        } = this.state;
        const { flagConfigurator, collections, flagConfiguratorChair } = this.props;

        return {
            menu,
            depth: activeMenuItemsStack.length,
            isSubCategoryClicked,
            subCategoryClicked,
            flagConfigurator,
            collections,
            flagConfiguratorChair
        };
    };

    __construct(props) {
        super.__construct(props);

        const {
            stack: activeMenuItemsStack = []
        } = history.location.state || {};

        this.state = {
            activeMenuItemsStack,
            menu: {},
            prevUrl: history.location.pathname,
            isSubCategoryClicked: false,
            subCategoryClicked: ''
        };
    }

    historyBackHook = () => {
        const { menuTree } = this.props;
        const { location: { state: { stack = [] } = {} } = {} } = history;

        this.setState({
            activeMenuItemsStack: stack,
            menu: (stack.length) ? this.createMenu(stack[0]) : menuTree
        });
        if (stack.length > 0) {
            this.changeSubMenuHeader();
        } else {
            this.changeMainMenuHeader();
        }
    };

    closeMenu() {
        this.setState({
            activeMenuItemsStack: [],
            menu: []
        });
    }

    render() {
        return (
            <McList
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(McListContainer);
