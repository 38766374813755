/* eslint-disable max-len */
export const COMPOSIZIONI_FISSE_REGEXP = 'Composizioni Fisse.{5}cm|[Cc]ucine [Ff]isse.{5}cm';
export const LETTI_REGEXP = '[A-Z,a-z]{1,10}\\s[A-Z,a-z]{1,10}\\s*[A-Z,a-z]{0,10}\\s-\\sLetto\\s[a-z]{1,11}';
export const CAMERETTE_REGEXP = '[Cc]amerette\\s.{1}\\s.{5}|[Cc]amerette\\s.{1}\\s[Ss]oppalco\\s.{1,14}';
export const N_OF_POSITIONS_BEFORE_CM = 4;
export const N_OF_POSITIONS_BEFORE_BED = 1;
export const KITCHEN_CATEGORIES = ['549'];
export const BED_CATEGORIES = ['638', '654', '662'];
export const CAMERETTE_CATEGORIES = ['814'];
export const KITCHEN_FORNITURES_CATEGORIES = ['560'];
export const DIVANI_LETTO_CATEGORIES = ['701'];
export const DIVANI_2_3_POSTI_CATEGORIES = ['741'];
export const CAMERE_COMPLETE_MODERNE_CATEGORIES = ['3067'];
export const ARMADI_ANTA_BATTENTE_CATEGORIES = ['3097'];
export const ARMADI_ANTA_SCORREVOLE_CATEGORIES = ['3133'];
export const COMO_CASSETTIERE_COMODINI_CATEGORIES = ['615'];
export const ILLUMINAZIONE_CATEGORIES = ['891'];
export const DIVANI_POLTRONE_RELAX_CATEGORIES = ['3334'];
export const MOBILI_BAGNO_CATEGORIES = ['3198'];

export const CUCINE_ELETTRODOMESTICI_REGEXP = 'Pp]romozioni|[Tt]ris e [Dd]uetto|[Pp]iani [Cc]ottura|[Pp]iani [Cc]ottura a [Ii]nduzione|^[Ff]orni$|^[Ff]orni a [Mm]icroonde$|^[Ll]avastoviglie da [Ii]ncasso$|^[Ff]rigoriferi [Ll]ibera [Ii]nstallazione$|^[Ff]rigoriferi da [Ii]ncasso$|^[Ll]avatrici da [Ii]ncasso$|[Cc]appe';
export const DIVANI_LETTO_REGEXP = '[Cc]lic [Cc]lac|[Ee]straibili|[Aa] [Rr]ibalta';
export const DIVANI_2_3_POSTI_REGEXP = '^[Dd]ivani [Dd]ue [Pp]osti$|^[Dd]ivani [Tt]re [Pp]osti$';
export const CAMERE_COMPLETE_MODERNE_REGEXP = '^[Aa]nta [Bb]attente$|^[Aa]nta [Ss]correvole$';
export const ARMADI_ANTA_BATTENTE_REGEXP = '^[Aa]rmadio [Aa] [Pp]onte$|^[Aa]rmadio [Aa]ngolare$|^[Aa]rmadio 6 [Aa]nte$|^[Aa]rmadio 4 [Aa]nte$|^[Aa]rmadio 3 [Aa]nte$|^[Aa]rmadio 2 [Aa]nte$|^[Aa]rmadi [Ss]tile [Cc]lassico$';
export const ARMADI_ANTA_SCORREVOLE_REGEXP = '^[Aa]rmadi 2 [Aa]nte [Mm]axi|^[Aa]rmadio 3 [Aa]nte$|^[Aa]rmadio 2 [Aa]nte$';
export const DIVANI_POLTRONE_RELAX_REGEXP = '^[Dd]ivani [Ee] [Pp]oltrone [Rr]ecliner|^[Rr]ecliner [Mm]anuale|^[Rr]ecliner [Ee]lettrico$';
export const COMO_CASSETTIERE_COMODINI_REGEXP = '^[Cc]omodini|^[Cc]assettiere [Ee] [Cc]om[òo]$|^[Ss]ettimini$';
export const ILLUMINAZIONE_REGEXP = '^[Ll]ed$|^[Ll]ed [Pp]er [Ii]nterni [Aa]rmadi$|^[Ss]ospensioni$|^[Pp]iantane$|^[Aa]pplique$|^[Ll]umi$|^[Ll]ampadari$|^[Pp]lafoniere$';
export const MOBILI_BAGNI_REGEXP = '^[Mm]obili\\s[Bb]agno\\s[Ss]ospesi$|^[Mm]obili\\s[Bb]agno\\s[Aa]\\s[Tt]erra$|^[Cc]olonne\\s[Ee]\\s[Pp]ensili$|^[Bb]asi\\s[Ee]\\s[Cc]assettiere$';
